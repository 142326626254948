
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Filter',
  props: {
    value: Number,
    options: {
      type: Array as () => string[],
      default: ['Option 1', 'Option 2'],
    },
  },
  setup(props, ctx) {
    const toggle = (v: number) => {
      ctx.emit('update:value', props.value === v ? null : v)
    }

    return {
      toggle,
    }
  },
})
