<template>
  <div class="filter">
    <button
      v-for="(item, index) in options"
      :class="{ active: value === index }"
      :key="item"
      @click="toggle(index)"
    >
      {{ item }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Filter',
  props: {
    value: Number,
    options: {
      type: Array as () => string[],
      default: ['Option 1', 'Option 2'],
    },
  },
  setup(props, ctx) {
    const toggle = (v: number) => {
      ctx.emit('update:value', props.value === v ? null : v)
    }

    return {
      toggle,
    }
  },
})
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  align-items: center;
  background: white;

  > * {
    flex: 1;
  }
}

button {
  @include fira-bold;
  font-size: 12px;
  padding: 0;
  margin: 0;
  border: 0;
  background: $athens-gray;
  height: 40px;
  color: $rock-blue;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

button.active {
  background: $tangerine;
  color: $c-white;
}

button + button {
  border-left: 1px solid $botticelli;
}
</style>
